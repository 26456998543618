<!-- 学员授权详情-->
<template>
    <div class="DetailsStudentEmpower">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员授权详情</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" id="form-1" class="form" inline label-width="120px">
            <el-form-item label="学员编号">
                <el-input v-model="form.number" placeholder="请输入学员编号" disabled></el-input>
            </el-form-item>
            <el-form-item label="学员姓名">
                <el-input v-model="form.name" placeholder="请输入学员姓名" disabled></el-input>
            </el-form-item>
            <el-form-item label="助记码">
                <el-input v-model="form.mnemonicCode" placeholder="请输入助记码" disabled></el-input>
            </el-form-item>
            <el-form-item label="学员号">
                <el-input v-model="form.movePhone" placeholder="请输入学员号" disabled></el-input>
            </el-form-item>
            <el-form-item label="学校/公司">
                <el-input v-model="form.studentCard" placeholder="请输入学校/公司" disabled></el-input>
            </el-form-item>
            <el-form-item label="入学年份">
                <el-date-picker v-model="form.enrollmentYear" type="year" placeholder="请选择入学年份" disabled>
                </el-date-picker>
            </el-form-item>
            <el-form-item label="现年级">
                <el-input v-model="form.currentGrade" placeholder="请输入现年级" disabled></el-input>
            </el-form-item>
            <el-form-item label="学生登录账号">
                <el-input v-model="form.studentNum" placeholder="请输入学生登录账号" disabled></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" :clearable="true" disabled>
                    <el-option v-for="item in form.typeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="家长姓名">
                <el-input v-model="form.parentsName" placeholder="请输入家长姓名" disabled></el-input>
            </el-form-item>
            <el-form-item label="手机号">
                <el-input v-model="form.phoneNumber" placeholder="请输入手机号" disabled></el-input>
            </el-form-item>
            <el-form-item label="联系邮箱">
                <el-input v-model="form.mailbox" placeholder="请输入联系邮箱" disabled></el-input>
            </el-form-item>
            <el-form-item label="证件号码">
                <el-input v-model="form.IdNumber" placeholder="请输入证件号码" disabled></el-input>
            </el-form-item>
            <el-form-item label="家庭住址">
                <el-input v-model="form.address" placeholder="请输入家庭住址" disabled></el-input>
            </el-form-item>
            <el-form-item label="性别" >
                <div id="sex" style="width: 300px;">
                    <el-radio-group v-model="form.sex" disabled>
                        <el-radio  :label="1">男</el-radio>
                        <el-radio  :label="2">女</el-radio>
                     </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="国籍">
                <el-input v-model="form.nationality" placeholder="请输入国籍" disabled></el-input>
            </el-form-item>
            <el-form-item label="出生日期">
                <el-date-picker v-model="form.birthday" type="date" placeholder="选择出生日期" disabled>
                </el-date-picker>
            </el-form-item>
            <el-form-item label="选择机构">
                <div id="sex" style="width: 300px;">
                    <el-radio-group v-model="form.skillSelect" disabled>
                        <el-radio  :label="1">培训</el-radio>
                        <el-radio  :label="2">考试</el-radio>
                        <el-radio  :label="3">两者</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item label="民族">
                <el-input v-model="form.nation" placeholder="请输入民族" disabled></el-input>
            </el-form-item>
            <el-form-item label="健康状况">
                <el-input v-model="form.health" placeholder="请输入健康状况" disabled></el-input>
            </el-form-item>
            <el-form-item label="爱好">
                <el-input v-model="form.hobby" placeholder="请输入爱好" disabled></el-input>
            </el-form-item>
            <el-form-item label="其他资料" >
                <el-input type="textarea" v-model="form.explain" style="width: 1160px;" placeholder="请输入说明" disabled></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    number: "", //学员编号
                    name: "", //学员姓名
                    mnemonicCode:"",//助记码
                    studentNumber:"",//学员号
                    company:"",//公司
                    enrollmentYear:"",//入学年份
                    currentGrade:"",//现年级
                    studentNum:"",//学生登录账号
                    type: "", //类型
                    typeChoose: [{
                           label: "入学",
                           value: "1"
                       },
                       {
                           label: "结业",
                           value: "2"
                       },
                       {
                           label: "未入学",
                           value: "3"
                       }
                   ], //类型选择
                   parentsName:"",//家长姓名
                   phoneNumber:"",//手机号
                   mailbox:"",//联系邮箱
                   IdNumber:"",//证件号码
                   address:"",// 家庭住址
                   sex:1,//性别
                   nationality:"",//国籍
                   birthday:"",//出生日期
                   skillSelect:1,//选择机构
                   nation:"",//民族
                   health:"",//健康状况
                   hobby:"",//爱好
                   explain:""//其他资料
                }
            }
        },
        created() {
            this.$request({
                url: '/api/student/detail',
                method: 'POST',
                data: {
                    id: this.$route.query.id
                }
            }).then(res => {
                var data = res.data.data
                var form = this.form
                form.number=data.number//学员编号
                form.name=data.username //学员姓名
                form.mnemonicCode=data.mcode//助记码
                form.studentNumber=data.student_card //学员号
                form.company=data.school_name //公司
                form.enrollmentYear= data.start_school//入学年份
                form.currentGrade=data.grade //现年级
                // form.studentNum=//学生登录账号
                form.type= data.school_type+''//类型
                form.parentsName=data.parent_name //家长姓名
                form.phoneNumber=data.mobile1 //手机号
                // form.mailbox= //联系邮箱
                form.IdNumber=data.idcard //证件号码
                form.address=data.address // 家庭住址
                form.sex=data.gender //性别
                form.nationality= data.nation2//国籍
                form.birthday= data.birthdate//出生日期
                form.skillSelect=data.mechanism_type //选择机构
                form.nation=data.nation //民族
                form.health=data.health //健康状况
                form.hobby= data.hobby//爱好
                form.explain=data.other //其他资料
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            
        }
    }
</script>

<style scoped="scoped">
    .DetailsStudentEmpower{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    /*    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    } */
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    #form-1 ::v-deep .el-textarea.is-disabled .el-textarea__inner{
        background-color: #FFFFFF;
        color: #333;
    }
    #form-1 ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #333;
    }
    /* 性别选择按钮 */
    #form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;
    
    }
    
    #form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }
    
    #form-1 ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    
</style>
